export const mainFieldNames = {
  // SOP
  profile_name: 'Profile Name',
  tire_profile: 'Tire Profile',
  inflation: 'Inflation Pressures',
  wheel_balance: 'Wheel Balancing',
  pull_point: 'Tread Depth Pull Points',
  brands: 'Brand Preferences',
  default: 'Default',
  service_type: 'Service Type',
  // SOP Procedures
  inspection_requirements: 'Inspection Requirements',
  service_installation_notes: 'Service and Installation',
  pickup_and_delivery: 'Pickup and Delivery',
  proof_of_delivery: 'Proof of Delivery',
  scrap_only: 'Scrap Only',
  scrap_pending: 'Scrap Pending',
  adjustment_notes: 'Adjustment Notes',
  wheel_instructions: 'Wheel Instructions',
  additional_instructions: 'Additional Instructions',
  // Account
  detail: 'Detail',
  account_type: 'Account Type',
  ld_na_number: 'Account Number',
  set_all_locations: 'Assign to all locations',
  company: 'Company',
  is_deleted: 'Deleted',
  comments: 'Comments',
  profile_type: 'Profile Type',
  is_x_one: 'X One',
  wheel_type: 'Wheel Type',
  rim_width: 'Rim Width',
  color_finish: 'Color/Finish',
  axle_position: 'Axle Position',
  wheel_diameter: 'Wheel Diameter',
  wheel_condition: 'Wheel Condition',
  wheel_material: 'Wheel Material',
  operating_company: 'Operating Company',
  wheel_position: 'Wheel Position',

  work_hours: 'Working Hours',
  ext: 'Ext',
  tire_dollar_amount: 'Tire dollar amount',
  mechanical_dollar_amount: 'Mechanical dollar amount',
  visible_externally: 'Visible to Service Provider',
  work_phone: 'Work Phone',
  last_name: 'Last Name',
  first_name: 'First Name',
  num_of_wheels: 'No. of Wheels',
  cell_phone: 'Cell Phone',
  num_of_tires: 'No. of Tires',
  contact_types: 'Contact Types',
  email_address: 'Email',
  contact_level: 'Contact Level',
  preferred_method: 'Preferred Method',
  job_title: 'Job Title',
  preferred_language: 'Preferred Language',
  group: 'Group',
  tire: 'Tire Contact Types',
  mechanical: 'Mechanical Contact Types',
  notification_subscription: 'Notification Preferences',
  fax: 'Fax',
  // Locations
  hoursOfOperations: 'Delivery Hours',
  operationHours: 'Hours of Operation',
  orderingHours: 'Ordering Hours',
  driver_signature_required: 'Driver Signature Required',
  action_code: 'Action code',
  address: 'Address',
  customer_name: 'Customer Name',
  pickup_delivery_instructions: 'Pickup & Delivery Instructions',
  customer_type: 'Account Type',
  extrnl_cust_id: 'Terminal/Location Id',
  customer_addr1: 'Address 1',
  customer_addr2: 'Address 2',
  customer_country: 'Country',
  customer_city: 'City',
  customer_state: 'State',
  customer_zip: 'Zip',
  parent_company_number: 'Parent Company Number',
  home_office_number: 'Home Office Number',
  bill_to_customer: 'Bill To Number',
  ship_to_customer: 'Ship To Number',
  phone_number: 'Main Phone',
  numberOfFleetTrucks: 'Amount of Fleet Trucks',
  numberOfFleetTrailers: 'Amount of Fleet Trailers',
  primaryTransportActivity: 'Primary Transport Activity',
  primaryVocation: 'Primary Vocation',
  secondaryTransportActivity: 'Secondary Transport Activity',
  secondaryVocation: 'Secondary Vocation',
  method_of_delivery: 'Method of Delivery',
  delivery_address: 'Delivery Address',
  delivery_city: 'Delivery City',
  delivery_state: 'Delivery State',
  delivery_zip: 'Delivery Zip',
  recipient_signature_required: 'Recipient Signature Required',
  cust_timezone: 'Time Zone',
  is_urban: 'urban flag',
  is_commercial: 'commercial flag',
  mechanical_services_enabled: 'Mechanical Services Enabled',
  howOrders: 'How Orders are Placed',
  emailLink: 'Ordering Email',
  orderingInstructions: 'Ordering Instructions',
  levelMap: 'Minimum Inventory Levels',
  onlineWebsite: 'Ordering Website',
  billing_child_last_update_date: 'Billing Child Last Update Date',
  delivery_address_same_as_physical: 'Delivery Address Same as Physical',
  bill_to_customer_name: 'Bill To Name',
  ship_to_customer_name: 'Ship To Name',
  home_office_name: 'Home Office Name',
  parent_company_name: 'Parent Company Name',
  customer_dba_name: 'Customer DBA Name',
  customer_number: 'Customer Number',
  urban_admin_preapproved_txs_limit_amount: 'Limit Amount',
  urban_admin_allow_create_vehicle: 'Allow providers to add vehicles',
  urban_admin_preapproved_txs_no_limits: 'Pre-approved Transactions No Limits',
  urban_admin_allow_preapproved_txs: 'Allow Pre-approved Transactions',
  allow_michelin_tires: 'Allow Michelin Tires',
  allow_uniroyal_tires: 'Allow Uniroyal Tires',
  allow_megamile_tires: 'Allow Megamile Tires',
  allow_bfgoodrich_tires: 'Allow BFGoodrich Tires',
  allow_oliver_tires: 'Allow Oliver Tires',
  oncall_sds_dashboard: 'ON Call DR Dashboard',
  // billing
  tire_details: 'Tire Details',
  ers_purchasing_procedures: 'ERS Purchasing Procedures',
  onsite_purchasing_procedures: 'On-site Purchasing Procedures',
  pri_ers_authorized_contact_level: 'Primary Contact Level to Authorize Service',
  secondary_ers_authorized_contact_level: 'Secondary Contact Level to Authorize Service',
  primary_method_to_request_service: 'Primary Contact to Request Authorization for Service',
  secondary_method_to_request_service: 'Secondary Contact to Request Authorization for Service',
  method_to_receive_signed_order: 'Method to Receive Signed Order',
  pri_tire_po_issuers: 'Primary Tire PO Issuer',
  sec_tire_po_issuers: 'Secondary Tire PO Issuer',
  required_for_ers_event: 'Required for ERS Event',
  pri_phone_number: 'Primary Phone Number',
  sec_phone_number: 'Secondary Phone Number',
  signed_order_receiver_other: 'Signed Order Receiver Other',
  pri_ers_authorized_contact_type: 'Primary Authorized Contact Type',
  sec_ers_authorized_contact_type: 'Secondary Authorized Contact Type',
  required_authorization_to_begin_service: 'Required Authorization for Billing Purposes',
  signed_order_receiver_contact: 'Signed Order Receiver Contact',
  signed_order_receiver_location: 'Signed Order Receiver Location',
  requested_photos: 'Requested Photos',
  wheel_details: 'Wheel Details',
  approvals: 'Pre Approval Limits',
  onsite_pre_approvals_limits: 'Onsite Pre Approval Limits',
  ers_pre_approvals_limits: 'ERS Pre Approval Limits',
  maxium_num_of_wheels: 'Maximum # of Wheels',
  maximum_num_of_tires: 'Maximum # of Tires',
  maximum_dollar_amount: 'Maximum Dollar Amount',
  general_information: 'General Information',
  owner_level: 'Owner Level',
  assigned_locations: 'Assigned Locations',
  participates_in_approve_orders: 'Participates in Approve Orders Program?',
  po_required_for_service: 'PO Required for Invoicing?',
  driver_name_required: 'Driver Name Required?',
  when_should_po_be_provided: 'When should PO be provided',
  // Tires Profile
  main: 'General Information',
  special_instructions: 'Special Instructions',
  single_call_indicator: 'Single Call Indicator',
  assign_to_all_locations: 'Assign to all locations',
  driver_can_override: 'Driver Can Override',
  application_type: 'Application Type',
  off_tire_disposition: 'Off Tire Disposition',
  vehicle_type_vmrs_code: 'Vehicle Type VMRS Code',
  vehicle_type: 'Vehicle Type',
  vehicle_attrs_tractor: 'Vehicle Attrs Tractor',
  vehicle_attrs_trailer: 'Vehicle Attrs Trailer',
  axles: 'Axles',
  steer: 'Steer',
  drive: 'Drive',
  trailer: 'Trailer',
  carry: 'Carry',
  lift: 'Lift',
  pusher: 'Pusher',
  tag: 'Tag',
  tire_replacement_priority: 'Tire Replacement Priority',
  tire_service_specifications: 'Tire Service Specifications',
  activity_if_pref_unavailable: 'Action if tire preferences unavailable',
  pressure_sensor: 'Pressure Sensor',
  tire_pressure_regulator: 'Tire Pressure Regulator',
  special_tools_required: 'Special tools required for removal',
  pressure_regulation_device_installed: 'Pressure regulation device installed',
  should_device_be_reinstalled: 'Should device be reinstalled',
  driver_has_special_tools: 'Driver has special tools',
  installed: 'Installed',
  disposition: 'Disposition',
  types: 'Types',
  mounting: 'Mounting',
  location: 'Location',
  first_choice: 'First Choice',
  second_choice: 'Second Choice',
  third_choice: 'Third Choice',
  all_axles: 'All',
  passenger_lt_priority: 'Passenger/LT Tire Replacement Priority',
  telematics_is_used: 'Telematics is used',
  telematics_list_of_platforms: 'Telematics List of Platforms',
  fms_is_used: 'FMS is used',
  fms_platform: 'FMS Platform',
  no_marketing_surveys: 'No Marketing Surveys',
};

export const ignoreFields = [
  'create_user',
  'created_date',
  'create_date',
  'update_user',
  'update_date',
  'current_version',
  'hash_key',
  'range_key',
  'gsi1_hash_key',
  'gsi4_hash_key',
  'gsi5_hash_key',
  'gsi1_range_key',
  'gsi4_range_key',
  'gsi5_range_key',
  // Fields
  'procedures',
  'relationship',
  'added_date',
  'owner_key',
  'owner_relationship',
];

export const getFieldLabel = (field: string) => (mainFieldNames as any)[field] || field;
